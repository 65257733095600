import dayjs, { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';

export function daysPassedFrom(date: Date) {
  if (date) {
    const now = dayjs();
    const theDate = dayjs(date);
    return now.diff(theDate, 'hours');
  }

  return '-';
}

export function formatShort(date: Date) {
  const dateObject = dayjs(date);

  if (!dateObject.isValid())
    return;

  return dateObject.format('DD MMM, YYYY');
}

export function getTimeFromDate(date: Date) {
  return dayjs(new Date(date)).format('HH:mm');
}

export function formatDaysAgo(date: Date) {
  extend(relativeTime);

  const dateObject = dayjs(date);

  if (!dateObject.isValid())
    return;

  return dayjs().to(dateObject);
}

export default function formatHumanReadableDate(date: Date) {
  if (date) {
    const theDate = dayjs(date);
    const now = dayjs();
    const weekBefore = dayjs().subtract(7, 'days');
    const weekAfter = dayjs().add(7, 'days');
    const dayAfter = dayjs().add(1, 'days');
    const dayBefore = dayjs().subtract(1, 'days');

    extend(isSameOrBefore);
    extend(isSameOrAfter);

    if (theDate.isSame(now, 'day'))
      return `Today ${theDate.format('- HH:mm')}`;

    if (
      theDate.isSameOrBefore(dayAfter, 'date')
      && theDate.isSameOrAfter(now, 'day')
    )
      return `Tomorrow ${theDate.format('- HH:mm')}`;

    if (
      theDate.isSameOrAfter(dayBefore, 'date')
      && theDate.isSameOrBefore(now, 'day')
    )
      return `Yesterday ${theDate.format('- HH:mm')}`;

    if (
      theDate.isSameOrBefore(weekAfter, 'date')
      && theDate.isSameOrAfter(weekBefore, 'date')
    )
      return theDate.format('dddd MMMM D, YYYY - HH:mm');

    return theDate.format('dddd MMMM D, YYYY - HH:mm');
  }
  else {
    return '-';
  }
}
